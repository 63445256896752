import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import axios from "axios";
import TrackVisibility from "react-on-screen";
import { Card } from "react-bootstrap";

import { getImage } from "./../../services";
import NOTFOUND from "./../../assets/image.png";

const Image = styled(Card.Img)`
  .card-img-top {
    width: 100%;
    height: auto !important;
    max-height: 650px !important;
  }

  ${(props) =>
    props.hide &&
    css`
      display: none;
    `};
`;

// color: ${props => props.primary ? "white" :

const ComponentToTrack = ({ isVisible, id, height, hide, url }) => {
  // const [url, setUrl] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const CancelToken = axios.CancelToken;
  //   const source = CancelToken.source();

  //   if (isVisible) fetchImage(source);

  //   return () => {
  //     if (!url) source.cancel();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isVisible, id]);

  // const fetchImage = async (source) => {
  //   try {
  //     setLoading(true);
  //     if (id) {
  //       const { data } = await getImage(id, source);
  //       const url = URL.createObjectURL(data);
  //       setUrl(url);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     if (axios.isCancel(error)) {
  //     } else {
  //       throw error;
  //     }
  //     setLoading(false);
  //   }
  // };

  const style = (loading, height) => {
    // const applyFix = height && height !== "undefined";
    return {
      transition: "0.5s filter linear",
      filter: `${loading ? "blur(50px)" : ""}`,
      // objectFit: `${applyFix ? "scale-down" : "unset"}`,
      // height: `${applyFix ? height * 0.5 + "px" : "unset"}`,
    };
  };
  return (
    <Image
      hide={hide}
      style={style(!url, height)}
      variant="top"
      src={url || NOTFOUND}
    />
  );
};

const ImageLoaderCmp = ({ id, height, hide, url }) => {
  return (
    <TrackVisibility>
      <ComponentToTrack id={id} height={height} hide={hide} url={url} />
    </TrackVisibility>
  );
};

export default React.memo(ImageLoaderCmp, (prevProps, props) => {
  return prevProps.id === props.id;
});
