import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import styled from "styled-components";

import LOGO from "../../assets/logo.png";

import BACK from "./../../assets/svg/flecha_back1.svg";
import BACKPRESS from "./../../assets/svg/flecha_back2.svg";

import "./index.css";

const NavbarWrapper = styled(Navbar)`
  height: 107px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px #f0f1f2;
  cursor: pointer;
`;
const Image = styled.img`
  width: 130px;
  margin-top: 10px;
`;
const DivBack = styled.div`
  position: absolute;
  left: 37px;
  top: 59px;

  height: 50px;
  cursor: pointer;
  background-image: url("${BACK}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:focus,
  &:hover {
    background-image: url("${BACKPRESS}");
  }
`;

const Header = () => {
  let history = useHistory();
  let location = useLocation();
  let { collectionId } = useParams();

  const goToHome = () => {
    history.push("/languages");
  };

  const goBack = () => {
    history.push(`/collection/${collectionId}`);
  };

  const hideMenu = () => {
    return (
      location.pathname.includes("collection") &&
      location.pathname.includes("artwork")
    );
  };

  return (
    <React.Fragment>
      <NavbarWrapper fixed="top" className="d-flex justify-content-center">
        <Navbar.Brand>
          <Image onClick={goToHome} src={LOGO} alt="Logo App" />
          {hideMenu() && <DivBack onClick={goBack} />}
        </Navbar.Brand>
      </NavbarWrapper>
    </React.Fragment>
  );
};

export default Header;
