import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import renderHTML from "react-render-html";
import styled from "styled-components";
import Header from "./../../components/Header";

import { AppContext } from "./../../AppContext";

import { checkData } from "./../../hoc";
import IMAGEMUSEO from "./../../assets/Museo-Thyssen-Bornemisza.jpg";
import { AppButton } from "./../../components/Button";


const ContainerW = styled(Container)`
  margin-top: 150px;
`;

const Home = () => {
  const { language } = useContext(AppContext);
  const toggleMenu = () => {
    const cb = document.querySelector(".bm-burger-button button");
    cb.click();
  };
  return (
    <React.Fragment>
      <Header />
      <ContainerW>
        <Row>
          <Col>
            <img className="w-100" src={IMAGEMUSEO} alt="" />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-justify mt-3">
              {language && language.descripcion
                ? renderHTML(language.descripcion)
                : ""}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <AppButton
              onClick={toggleMenu}
              style={{ marginTop: 30, marginBottom: 20 }}
            >
              {language ? language.BOTON_INICIO : ""}
            </AppButton>
          </Col>
        </Row>
      </ContainerW>
    </React.Fragment>
  );
};

export default checkData(Home);
