import React from "react";
import videojs from "video.js";
import ReactGA from "react-ga";

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    // instantiate video.js
    const self = this;
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      console.log("onPlayerReady", this);
      this.on("ended", function () {
        console.log(`Play Audio -${self.props.name}`);
        ReactGA.event({
          category: "Artwork",
          action: `Play Video -${self.props.name}`,
        });
      });
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div data-vjs-player>
        <video ref={(node) => (this.videoNode = node)} className="video-js" />
      </div>
    );
  }
}
