import React, { useEffect, useContext, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { Row, Col, Container, Card } from "react-bootstrap";
import styled from "styled-components";

import ClockLoader from "react-spinners/ClockLoader";
import ReactGA from "react-ga";

import "react-virtualized/styles.css";

import Header from "./../../components/Header";
import ImageLoader from "./../../components/ImageLoader";
import { checkData } from "./../../hoc";
import { AppContext } from "./../../AppContext";

import PLANTAPRESSSVG from "./../../assets/svg/planta_presionado.svg";
import VISTAGRIDSVG from "./../../assets/svg/vista_grid.svg";
import VISTALISTASVG from "./../../assets/svg/vista_lista.svg";

const ContainerWrapper = styled(Container)`
  margin-top: 130px;
  margin-bottom: 30px;
`;
const CardWrapper = styled(Card)`
  box-shadow: 8px 8px 5px #dfdedb;
  &:focus,
  &:hover {
    box-shadow: 10px 10px 5px #dfdedb;
  }
`;
const CardTitle = styled(Card.Title)`
  font-family: Armata-Regular;
  color: #150f15;
  font-size: 2em;
`;
const CardText = styled(Card.Text)`
  font-family: Armata-Regular;
  margin-bottom: 0;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: bold;
`;
const CardText2 = styled(Card.Text)`
  font-family: Armata-Regular;
  margin-bottom: 0;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 700;
`;
const SpanAutor = styled(Card.Text)`
  font-family: Armata-Regular;
  margin-bottom: 0;
  font-size: 15px;
  text-align: right;
  margin-right: 5px;
`;

const CardTextTitle = styled(Card.Text)`
  font-family: Armata-Regular;
  margin-bottom: 0;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin: 0 0 0 20px;
`;

const Vista = styled.div`
  display: flex;
  justify-content: space-evenly;
  span {
    font-family: Armata-Regular;
    margin-bottom: 0;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 700;
  }
  img.list {
    height: 20px;
    cursor: pointer;
  }
  img.square {
    height: 20px;
    cursor: pointer;
  }
`;

const VistaGridDiv = styled.div`
  background-image: url("${VISTAGRIDSVG}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 21px;
  height: 21px;
  margin-left: 5px;
  cursor: pointer;
  /* &:focus,&:hover{
    background-image: url('${PLANTAPRESSSVG}');
  } */
`;

const Element = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Collection = () => {
  let { collectionId } = useParams();
  const { collections, language, filter } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [obras, setObras] = useState([]);
  const [view, setView] = useState("square");
  const [ra, setRa] = useState(Math.floor(Math.random() * 11));

  useEffect(() => {
    fetchCollection();

    setTimeout(() => {
      setRa(Math.floor(Math.random() * 11));
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, filter]);

  const fetchCollection = async () => {
    try {
      setLoading(true);
      if (collectionId) {
        if (filter) {
          filterCollections();
        } else {
          const data = collections.filter((item) => {
            return parseInt(item.id) === parseInt(collectionId);
          });
          if (data.length) {
            runGA(data[0]);
            setObras(sorts(data[0].obras));
          }
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const sorts = (array) => {
    for (let i = 0; i < array.length - 1; i++) {
      for (let j = i + 1; j < array.length; j++) {
        if (parseInt(array[i].orden) > parseInt(array[j].orden)) {
          let aux = array[i];
          array[i] = array[j];
          array[j] = aux;
        }
      }
    }
    return array;
  };

  const runGA = (item) => {
    if (
      item.nombre &&
      language &&
      language.abreviatura &&
      item.nombre[language.abreviatura]
    ) {
      ReactGA.event({
        category: "Collection",
        action: `Visited -${item.nombre[language.abreviatura]}`,
        value: parseInt(collectionId),
      });
    }
  };

  const filterCollections = () => {
    setObras(sorts(filter.obras));
  };

  const changeView = (value) => {
    setView(value);
  };

  return (
    <React.Fragment>
      <Header />
      <ContainerWrapper key={ra} id="collections">
        {loading && (
          <Row style={{ marginTop: 260 }}>
            <Col className="d-flex justify-content-center">
              <ClockLoader size={100} color={"#D58A95"} loading={true} />
            </Col>
          </Row>
        )}

        {!loading && (
          <React.Fragment>
            <Row className="mb-3 d-flex justify-content-end">
              <Col className="col-10">
                <Vista className="d-flex justify-content-end">
                  <span style={{ marginRight: 20 }}>
                    {language && language.VISTA}
                  </span>
                  <VistaGridDiv
                    style={{ marginRight: 20 }}
                    onClick={() => {
                      changeView("square");
                    }}
                  />

                  <img
                    style={{ marginRight: 20 }}
                    onClick={() => {
                      changeView("list");
                    }}
                    className="square"
                    src={VISTALISTASVG}
                    alt=""
                  />
                </Vista>
              </Col>
            </Row>
            <Row className="sg-container d-flex justify-content-center">
              {obras.map((item) => {
                if (language && item.titulo[language.abreviatura]) {
                  return (
                    <Col
                      className="m-2 col-12 d-flex justify-content-center"
                      key={`obras-${item.id}`}
                    >
                      <Link
                        className="w-100 no-link"
                        key={`link-art${item.id}`}
                        to={`/collection/${collectionId}/artwork/${item.id}`}
                      >
                        {view === "square" && (
                          <CardWrapper id={`artwork-${item.id}`}>
                            {(!item.es_sala || item.es_sala === "0") &&
                              item.imagen_id && (
                                <ImageLoader
                                  key={`image-${item.imagen_id}`}
                                  id={item.imagen_id}
                                  url={item.imageUrl}
                                  obra={item.id}
                                />
                              )}
                            <Card.Body>
                              <CardText>
                                {item.audioguia && (
                                  <React.Fragment>
                                    {language.AUDIOGUIA} {item.audioguia}
                                  </React.Fragment>
                                )}
                                &nbsp;
                              </CardText>
                              <Row>
                                <Col className="col-12">
                                  <CardTitle>
                                    {item.titulo[language.abreviatura]}
                                    &nbsp;
                                  </CardTitle>
                                  <CardText2>
                                    {` ${
                                      item.planta
                                        ? `${language && language.PLANTA} ${
                                            item.planta
                                          }`
                                        : ""
                                    } `}{" "}
                                    {`${
                                      item.planta && item.sala_id && item.autor
                                        ? "-"
                                        : ""
                                    } `}
                                    {` ${
                                      item.sala_id &&
                                      (item.es_sala === "0" ||
                                        item.es_sala === null)
                                        ? `${language && language.SALA} ${
                                            item.sala_id
                                          }`
                                        : ""
                                    }`}
                                  </CardText2>
                                </Col>
                              </Row>
                            </Card.Body>
                          </CardWrapper>
                        )}

                        {view === "list" && (
                          <CardWrapper
                            id={`artwork-${item.id}`}
                            style={{ cursor: "pointer" }}
                          >
                            <Card.Body>
                              <Row>
                                <Col
                                  style={{ padding: "0 5px 0 0px" }}
                                  className="col-3"
                                >
                                  <CardTextTitle>
                                    {item.audioguia && (
                                      <React.Fragment>
                                        {language.AUDIOGUIA} {item.audioguia}
                                      </React.Fragment>
                                    )}
                                    &nbsp;
                                  </CardTextTitle>
                                </Col>
                                <Col
                                  style={{ padding: "0 0 0 10px" }}
                                  className="col-9"
                                >
                                  <SpanAutor>
                                    {" "}
                                    {item.titulo[language.abreviatura]}
                                    &nbsp;
                                  </SpanAutor>
                                </Col>
                              </Row>
                            </Card.Body>
                          </CardWrapper>
                        )}
                      </Link>
                    </Col>
                  );
                }
                return null;
              })}
            </Row>
            <Element />
          </React.Fragment>
        )}
      </ContainerWrapper>
    </React.Fragment>
  );
};

export default checkData(Collection);
