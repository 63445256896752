import React, { useEffect, useContext, useState } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import { reactLocalStorage } from "reactjs-localstorage";
import styled, { createGlobalStyle } from "styled-components";

import { AppContext } from "./AppContext";

import Drawer from "./components/Drawer";
import Home from "./pages/Home";
import Collection from "./pages/Collection";
import Artwork from "./pages/Artwork";
import Languages from "./pages/Languages";
import Questionnaire from "./pages/Questionnaire";
import Splash from "./pages/Splash";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";
import "./styles/normalize.css";
import { login, getAllData } from "./services";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #e8e6e0;
  }
`;

const Main = styled.main``;

function usePageViews() {
  let location = useLocation();
  React.useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }, [location]);
}

const App = () => {
  usePageViews();
  const [loading, setLoading] = useState(true);
  const {
    setLanguages,
    setCollections,
    setSalas,
    setPlantas,
    setSubMenus,
  } = useContext(AppContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    main();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const main = async () => {
    setLoading(true);
    await initialize();
    setLoading(false);
    // await setInterval(() => {
    //   initialize();
    // }, 1000 * 60 * 5);
  };

  const initialize = async () => {
    await doLogin();
    await fetchAll();
  };

  const doLogin = async () => {
    try {
      const {
        data: { token },
      } = await login();

      reactLocalStorage.set("token", token);
      ReactGA.set({
        userId: token,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAll = async () => {
    try {
      const {
        data: { collections, languages, plantas, salas, submenu },
      } = await getAllData();
      setLanguages(languages);
      setCollections(collections);
      setPlantas(plantas);
      setSalas(salas);
      setSubMenus(submenu);
    } catch (error) {
      if (error.message === "Network Error") {
        console.log(error.message);
      } else {
        console.log(error.message);
      }
    }
  };
  usePageViews();
  return (
    <React.Fragment>
      <Drawer />
      {loading && <Splash />}
      {!loading && (
        <div id="page-wrap">
          <Main>
            <GlobalStyle />
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              {/* <Route exact path="/lock">
                <Lock />
              </Route> */}
              <Route path="/languages">
                <Languages />
              </Route>
              <Route exact path="/collection/:collectionId">
                <Collection />
              </Route>
              <Route
                exact
                path="/collection/:collectionId/subCollection/:subCollectionId"
              >
                <Collection />
              </Route>
              <Route exact path="/collection/:collectionId/artwork/:artworkId">
                <Artwork />
              </Route>
              <Route exact path="/questionnaire">
                <Questionnaire />
              </Route>
              <Redirect to="/" />
            </Switch>
          </Main>
        </div>
      )}
    </React.Fragment>
  );
};

export default App;
