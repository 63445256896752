import React from "react";
import { Row, Col } from "react-bootstrap";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
import styled from "styled-components";
import LOGO from "./../../assets/logo.png";

const RowW = styled(Row)`
  padding: 0;
  margin: 0;
  height: 100vh;
  background-color: #e8e6e0 !important;
`;
const IMG = styled.img`
  width: 150px;
  /* height: 154px; */
`;
const override = css`
  margin: 20px 0px 0 25px;
`;

const Splash = () => {
  return (
    <RowW>
      <Col className="d-flex justify-content-center align-items-center">
        <div>
          <IMG src={LOGO} alt="" />
          <PulseLoader
            css={override}
            size={15}
            color={"#d12415"}
            loading={true}
          />
        </div>
      </Col>
    </RowW>
  );
};

export default Splash;
